import React from 'react';
import { useTranslation } from 'react-i18next';
import layoutStyles from '../../styles/layout.module.css';
import styles from './NewIdeas.module.css';

const NewIdeas: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.colorWrapper}>
      <div id="NewIdeas" className={`${layoutStyles.container} ${styles.newIdeas}`}>
        <div className={styles.trial}>
          <h2>{t('footer.trial.title')}</h2>
          <p>{t('footer.trial.description')}</p>
          <button className={styles.trialButton}>{t('footer.trial.button')}</button>
        </div>
      </div>
    </div>
  );
};

export default NewIdeas;
