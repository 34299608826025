import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaGithub, FaFigma } from 'react-icons/fa';
import layoutStyles from '../../styles/layout.module.css';
import styles from './Hero.module.css';

const Hero: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.colorWrapper}>
      <main className={`${layoutStyles.container} ${styles.main}`}>
        <div className={styles.content}>
          <h1>{t('hero.title')}</h1>
          <p className={styles.subtitle}>
            {t('hero.description')}
          </p>
          {/* <div className={styles.buttons}>
          <button className={styles.githubBtn}>
            <FaGithub /> View on GitHub
          </button>
          <button className={styles.figmaBtn}>
            <FaFigma /> Get Figma file
          </button>
          </div> */}
        </div>
        <div className={styles.heroImage}>
          <img src="/ideas-progress-vision-inspiration-design-concept.jpg" alt="Marketing illustration" />
        </div>
      </main>
    </div>
  );
};

export default Hero;
