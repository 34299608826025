import React from 'react';
import { useTranslation } from 'react-i18next';
import layoutStyles from '../../styles/layout.module.css';
import styles from './Recognitions.module.css';

const Recognitions: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.colorWrapper}>
      <section id="Recognitions" className={`${layoutStyles.container} ${styles.worldPotential}`}>
        <div className={styles.potentialContent}>
          <h2>{t('worldPotential.title')}</h2>
          <p>{t('worldPotential.description')}</p>
          <p>{t('worldPotential.description2')}</p>
          <ul className={styles.featureList}>
            <li>{t('worldPotential.items.item1')}</li>
            <li>{t('worldPotential.items.item2')}</li>
            <li>{t('worldPotential.items.item3')}</li>
            <li>{t('worldPotential.items.item4')}</li>
            <li>{t('worldPotential.items.item5')}</li>
          </ul>
          <p>{t('worldPotential.summary')}</p>
        </div>
        <div className={styles.mapImages}>
          <img
            src="/song-creator-app.svg"
            alt={t('worldPotential.images.lineChart')}
            className={styles.mapImage}
          />
          <img
            src="/melody-creator-app.svg"
            alt={t('worldPotential.images.usMap')}
            className={styles.mapImage}
          />
          <img
            src="/Music-Touch-Song-creator-editor.jpg"
            alt={t('features.images.Calendar')}
            className={`${styles.mapImage} ${styles.fullWidth}`}
          />
        </div>

      </section>
    </div>
  );
};

export default Recognitions;
