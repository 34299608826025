import React from 'react';
import { useTranslation } from 'react-i18next';
import layoutStyles from '../../styles/layout.module.css';
import styles from './Footer.module.css';

const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.colorWrapper}>
      <footer className={`${layoutStyles.container} ${styles.footer}`}>
        <div className={styles.footerContent}>
          <div className={styles.footerLinks}>
            <div className={`${styles.linkColumn} ${styles.logoSection}`}>
              <div className={styles.logo}>
                <img src="/logo_jbp_nofondo.png" alt="JBP SOFTWARE" />
              </div>
              <div className={styles.contactUs}>
                <h3>{t('footer.sections.contact.title', 'Contáctenos')}</h3>
                <a href="mailto:jbp.software.sas@gmail.com">jbp.software.sas@gmail.com</a>
              </div>
            </div>

            {/* Legal Section */}
            <div className={`${styles.linkColumn} ${styles.legal}`}>
              <h3>{t('footer.sections.legal.title')}</h3>
              <a href="#privacy">{t('footer.sections.legal.privacy')}</a>
              <a href="#licensing">{t('footer.sections.legal.licensing')}</a>
              <a href="#terms">{t('footer.sections.legal.terms')}</a>
            </div>

            {/* Download Section */}
            <div className={`${styles.linkColumn} ${styles.download}`}>
              <h3>{t('footer.sections.download.title')}</h3>
              <a href="#ios">{t('footer.sections.download.ios')}</a>
              <a href="#android">{t('footer.sections.download.android')}</a>
              <a href="#windows">{t('footer.sections.download.windows')}</a>
              <a href="#macos">{t('footer.sections.download.macos')}</a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
