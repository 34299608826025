import React from 'react';
import Navbar from './components/Navbar/Navbar';
import Hero from './components/Hero/Hero';
import Features from './components/Features/Features';
import Recognitions from './components/Recognitions/Recognitions';
import NewIdeas from './components/NewIdeas/NewIdeas';
import Footer from './components/Footer/Footer';
import styles from './App.module.css';

const App: React.FC = () => {
  return (
    <div className={styles.app}>
      <Navbar />
      <Hero />
      {/* <Brands /> */}
      <Features />
      <Recognitions />
      <NewIdeas />
      <Footer />
    </div>
  );
};

export default App;
