import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiMenu3Line } from 'react-icons/ri';
import layoutStyles from '../../styles/layout.module.css';
import styles from './Navbar.module.css';

const Navbar: React.FC = () => {
  const { i18n } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const scrollToSection = (sectionId: string) => {
    setIsMenuOpen(false); // Close mobile menu after clicking
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ 
        behavior: 'smooth',
        block: 'start'
      });
    }
  };

  return (
    <nav className={styles.colorWrapper}>
      <div className={`${layoutStyles.container} ${styles.nav}`}>
        <div className={styles.logo}>
          <img className={styles.logoIcon} src="/logo_jbp_nofondo.png" alt="JBP SOFTWARE" />
        </div>
        <div className={styles.navLinksContainer}>
          <div className={`${styles.navLinks} ${isMenuOpen ? styles.showMobile : ''}`}>
            <a onClick={() => scrollToSection('Applications')} href="#Applications">Aplicaciones</a>
            <a onClick={() => scrollToSection('Recognitions')} href="#Recognitions">Reconocimientos</a>
            <a onClick={() => scrollToSection('NewIdeas')} href="#NewIdeas">Nuevos proyectos</a>
          </div>
          <div className={styles.actions}>
            <div className={styles.langSwitch}>
              <button 
                onClick={() => changeLanguage('en')}
                className={i18n.language === 'en' ? styles.selected : ''}
              >
                EN
              </button>
              <button 
                onClick={() => changeLanguage('es')}
                className={i18n.language === 'es' ? styles.selected : ''}
              >
                ES
              </button>
            </div>
          </div>
          <button 
            className={styles.menuButton}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <RiMenu3Line size={24} />
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
