import React from 'react';
import { useTranslation } from 'react-i18next';
import layoutStyles from '../../styles/layout.module.css';
import styles from './Features.module.css';

const Features: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.colorWrapper}>
      <section id="Applications" className={`${layoutStyles.container} ${styles.features}`}>
        <div className={styles.featureImages}>
          <div className={styles.dashboardGrid}>
            <img src="/rhythm-creator-app.svg" alt={t('features.images.chart')} />
            <img src="/volquetappjpg.png" alt={t('features.images.calendar')} />

          </div>
        </div>
        <div className={styles.featureContent}>
          <h2>{t('features.title')}</h2>
          <p>
            {t('features.description',
              'Deliver great service experiences fast - without the complexity of traditional ITSM solutions. Accelerate critical development work, eliminate toil, and deploy changes with ease.')}
          </p>
          <ul className={styles.featureList}>
            <li>{t('features.items.feature1')}</li>
            <li>{t('features.items.feature2')}</li>
            <li>{t('features.items.feature3')}</li>
          </ul>
          <p>
            {t('features.summary')}
          </p>
          <p>
            {t('features.summary2')}
          </p>
        </div>

      </section>
    </div>
  );
};

export default Features;
